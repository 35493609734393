import { ItemTypes } from '~/types';
import removeUndefinedKeys from '../../utils/removeUndefinedKeys';
import { toTimestamp } from '../../utils/date';
import songwhipApi from '../songwhipApi';

export interface PartialArtist {
  type: ItemTypes.ARTIST;
  id: number;
  name: string;
  image: string | null;
}

export interface PartialAlbum {
  type: ItemTypes.ALBUM;
  id: number;
  name: string;
  image: string | null;
  artistId: number;
  artistName: string;
  isPrerelease?: boolean;
  isDraft?: boolean;
  releaseDate?: number;
  upc?: string;
}

export interface PartialTrack {
  type: ItemTypes.TRACK;
  id: number;
  name: string;
  image: string | null;
  artistId: number;
  artistName: string;
  isrc?: string;
}

export interface PartialCustomPage {
  type: ItemTypes.CUSTOM_PAGE;
  id: number;
  name: string;
  image: string | null;
  artistId: number;
  artistName: string;
}

export type PartialItem =
  | PartialArtist
  | PartialAlbum
  | PartialTrack
  | PartialCustomPage;

const getUserPagesApi = async ({ userId }: { userId: number }) => {
  const {
    data: { items },
  } = (await songwhipApi(`users/${userId}/accounts/pages`)).json as {
    data: {
      items: (
        | PartialArtist
        | (Omit<PartialAlbum, 'releaseDate'> & { releaseDate: string })
        | PartialTrack
        | PartialCustomPage
      )[];
    };
  };

  return items.map(
    (item): PartialItem =>
      removeUndefinedKeys({
        ...item,

        releaseDate:
          'releaseDate' in item ? toTimestamp(item.releaseDate) : undefined,
      })
  );
};

export default getUserPagesApi;
